import { Link } from "gatsby"
import * as React from "react"

const Search = () => {
  const [active, setActive] = React.useState(false)
  const bannerRef = React.useRef()
  const iconRef = React.useRef()

  const handleClickOutside = e => {
    if (
      bannerRef.current &&
      !bannerRef.current.contains(e.target) &&
      !iconRef.current.contains(e.target)
    ) {
      setActive(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <>
      <div className="icons-container">
        <ul className="icons">
          <li>
            <Link
              ref={iconRef}
              to="#search"
              className={`icon solid ${active ? "fa-times" : "fa-search"}`}
              onClick={() => setActive(prev => !prev)}
            >
              <span className="label">Search</span>
            </Link>
          </li>
        </ul>
      </div>
      {active && (
        <div
          ref={bannerRef}
          className="search-banner"
          // onMouseLeave={props.onMouseLeave}
        >
          <section id="search" className="alt">
            <form method="get" action="/search">
              <input type="hidden" name="mode" value="default" />
              <input
                autoFocus
                type="text"
                name="q"
                id="query"
                placeholder="Search"
              />
            </form>
          </section>
        </div>
      )}
    </>
  )
}

export default Search
