/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import "./sass/main.scss"
import "./sass/fontawesome-all.min.css"
import Header from "./header/header"

const Layout = ({ header, children, landingWrapper }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/* Wrapper */}
      <div id="wrapper">
        {header && <Header />}
        {/* Main */}
        <div id="main">
          <div className={`inner ${landingWrapper && "landing-wrapper"}`}>
            {/* Content */}
            <main>{children}</main>
          </div>
        </div>
        {/* <Sidebar /> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  header: true
}

export default Layout
