import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import Logo from "../../images/logo"
import { menuItems } from "./menuItems"
import Search from "./search"
import Sidebar from "./sidebar"

const MenuBanner = props => {
  const selfRef = React.useRef()

  const handleClickOutside = React.useCallback(
    e => {
      if (selfRef.current && !selfRef.current.contains(e.target)) {
        props.onMouseLeave()
      }
    },
    [props]
  )

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
  }, [handleClickOutside])

  return (
    <div
      ref={selfRef}
      className="menu-banner"
      onMouseLeave={props.onMouseLeave}
      aria-hidden="true"
    >
      <div className="row">
        {props.content.map(linkSection => (
          <div
            className={`col-${Math.floor(
              12 / props.content.length
            )} link-section`}
          >
            <h3>{linkSection.title}</h3>
            <ul>
              {linkSection.links.map(link => (
                <li>
                  <Link to={link.path}>{link.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

const MenuItem = props => {
  return (
    <>
      <div
        ref={props.itemRef}
        className={`top-menu-item ${props.active ? "hover" : ""}`}
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        onKeyDown={props.onMouseLeave}
      >
        {props.name}
      </div>
      {props.active && (
        <MenuBanner
          content={props.content}
          onMouseLeave={props.onOutOfBanner}
        />
      )}
    </>
  )
}

const Menu = ({ menuItems }) => {
  const [active, setActive] = React.useState(null)

  const menuItemRefs = React.useRef(menuItems.map(() => React.createRef()))

  const onLeave = (e, index) => {
    const boundingBox =
      menuItemRefs.current[index].current.getBoundingClientRect()

    const topEdgeDist = Math.abs(boundingBox.top - e.clientY)
    const bottomEdgeDist = Math.abs(boundingBox.bottom - e.clientY)
    const leftEdgeDist = Math.abs(boundingBox.left - e.clientX)
    const rightEdgeDist = Math.abs(boundingBox.right - e.clientX)

    const min = Math.min(
      topEdgeDist,
      bottomEdgeDist,
      leftEdgeDist,
      rightEdgeDist
    )

    if (min !== bottomEdgeDist) {
      // only hide if mouse exits from anywhere except the bottom
      setActive(null)
    }
    // if (min === topEdgeDist) {
    //   console.log("top")
    // }
    // if (min === bottomEdgeDist) {
    //   console.log("bottom")
    // }
    // if (min === leftEdgeDist) {
    //   console.log("left")
    // }
    // if (min === rightEdgeDist) {
    //   console.log("right")
    // }
  }

  return (
    <div className="menu-container">
      {menuItems.map((item, index) => (
        <MenuItem
          key={`${item.name}-${index}`}
          active={active === item.name}
          name={item.name}
          content={item.content}
          onMouseOver={() => setActive(item.name)}
          onClick={() => setActive(item.name)}
          onMouseLeave={e => onLeave(e, index)}
          onOutOfBanner={() => setActive(null)}
          itemRef={menuItemRefs.current[index]}
        />
      ))}
    </div>
  )
}

const Header = ({ drilldown }) => {
  return (
    <header id="header">
      <Sidebar menuItems={menuItems} />
      <div className="logo">
        <Link to="/">{Logo()}</Link>
      </div>
      <Menu menuItems={menuItems} />
      <Search />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
