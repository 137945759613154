import * as React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

const Menu = props => {
  return (
    <nav id="menu">
      {props.title && (
        <header className="major">
          <h2>{props.title}</h2>
        </header>
      )}
      <ul>{props.children}</ul>
    </nav>
  )
}

const MenuItem = props => {
  const [open, setOpen] = React.useState(false)

  const handleMenu = () => {
    setOpen(prev => !prev)
  }

  if (props.children) {
    return (
      <li>
        <span className={`opener ${open ? "active" : ""}`} onClick={handleMenu}>
          {props.name}
        </span>
        <ul>{props.children}</ul>
      </li>
    )
  }
  return (
    <li>
      <Link to={props.link}>{props.name}</Link>
    </li>
  )
}

const Sidebar = props => {
  const [open, setOpen] = React.useState(false)
  const sidebarRef = React.useRef()
  const burgerRef = React.useRef()

  const handleSidebar = () => {
    setOpen(prev => !prev)
  }

  const handleClickOutside = e => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(e.target) &&
      !burgerRef.current.contains(e.target)
    ) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <>
      <div
        ref={sidebarRef}
        id="sidebar"
        className={`wrapper searchDiv ${open ? "" : "inactive"}`}
      >
        <div className="inner">
          {/* Search */}
          {/* <section id="search" className="alt">
            <form method="post" action="#">
              <input type="text" name="query" id="query" placeholder="Search" />
            </form>
          </section> */}
          {/* Menu */}
          <Menu>
            {" "}
            {/* title="Menu"> */}
            {/* <MenuItem link="/" name="Homepage" />
            <MenuItem id="categories" link="/" name="Categories">
              <StaticQuery
                query={graphql`
                  query Categories {
                    categories: allStrapiCategory {
                      nodes {
                        name
                        slug
                      }
                    }
                  }
                `}
                render={data =>
                  data.categories.nodes.map(category => (
                    <MenuItem
                      key={category.slug}
                      link={`/category/${category.slug}`}
                      name={category.name}
                    />
                  ))
                }
              />

              <MenuItem link="/" name="> More categories" />
            </MenuItem> */}
            {props.menuItems.map((item, index) => (
              <MenuItem key={`${item.name}-${index}`} name={item.name}>
                {item.content.map(linkSection => (
                  <>
                    <div className="link-section-title">{linkSection.title}</div>
                    {linkSection.links.map(link => (
                      <MenuItem name={link.text} link={link.path} />
                    ))}
                  </>
                ))}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <div className="icons-container">
        <Link
          to="#sidebar"
          className="hamburger icon solid fa-bars"
          onClick={handleSidebar}
          ref={burgerRef}
        >
          <span className="label">Menu</span>
        </Link>
      </div>
    </>
  )
}

export default Sidebar
