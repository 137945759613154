export const menuItems = [
  {
    name: "Cars",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section with a long text right here",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here with more words, very verbose",
        links: [
          {
            path: "/",
            text: "This text is slightly longer, how does it look?",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
  {
    name: "Food",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
  {
    name: "Home",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
  {
    name: "Money",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
  {
    name: "Carbon",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
  {
    name: "Fashion",
    content: [
      {
        title: "Some title here",
        links: [
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
          {
            path: "/",
            text: "Some section",
          },
        ],
      },
    ],
  },
]
